import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BModal,
  BForm,
  BCardText,
  BImg,
  BBadge
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import Pagination from '@/@core/components/paginations/Pagination.vue';
import {
  PriceAction,
  PriceGetter,
  PriceNamespace
} from '@/store/price/price.module-types';
import {
  CustomerLevel,
  CutType,
  PriceListViewModel,
  PriceViewModel,
  UpdatePriceInput,
  PriceValueInput
} from '@/api/api';
import { dispatchPriceAction } from '@/store/price/price.dispatch';
import PriceAreaFormModal from '@/components/prices/PriceAreaFormModal.vue';
import ApiClientFactory from '@/api/apiClientFactory';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    BCardText,
    Pagination,
    BImg,
    BBadge,
    PriceAreaFormModal
  }
})
export default class VipPriceTab extends Vue {
  @PriceNamespace.Getter(PriceGetter.priceList)
  priceList!: PriceListViewModel[];
  areas: number[] = [0];
  updatePriceInputs: UpdatePriceInput[] = [];
  @Prop({ required: true })
  vipType!: CustomerLevel;
  @Prop({ required: true })
  name!: string;
  mounted() {
    dispatchPriceAction(PriceAction.loadPriceList);
  }

  @Watch('priceList')
  priceListUpdated() {
    // reset and set updatePriceInputs
    this.updatePriceInputs = [];
    this.priceList.forEach((value) => {
      if (value.haveMachining || value.noMachining) {
        let priceViewModels: PriceViewModel[] = [];
        if (value.priceViewModels) {
          priceViewModels = value.priceViewModels.filter(
            (_) => _.customerLevel === this.vipType
          ) as PriceViewModel[];
        }

        if (value.noMachining) {
          this.updatePriceInputs.push(
            this.getUpdatePriceInputByFromPriceViewModels(
              priceViewModels,
              value.productId,
              false
            )
          );
        }

        if (value.haveMachining) {
          this.updatePriceInputs.push(
            this.getUpdatePriceInputByFromPriceViewModels(
              priceViewModels,
              value.productId,
              true
            )
          );
        }
      }
    });
  }

  getUpdatePriceInputByFromPriceViewModels(
    priceViewModels: PriceViewModel[],
    productId: number,
    machining: boolean
  ): UpdatePriceInput {
    return new UpdatePriceInput({
      productId,
      machining,
      cutType: CutType.None,
      customerLevel: this.vipType,
      priceValues: priceViewModels.some(
        (n) => n.machining === machining && n.cutType === CutType.None
      )
        ? priceViewModels.filter(
            (n) => n.machining === machining && n.cutType === CutType.None
          )[0].value
        : this.getDefaultPriceValues()
    });
  }

  getDefaultPriceValues(): PriceValueInput[] {
    return this.areas.map(
      (value) =>
        new PriceValueInput({
          area: value,
          price: 0,
          id: '',
          maxQuantity: 0,
          minQuantity: 0
        })
    );
  }

  getUpdatePriceInputByCondition(productId: number, machining: boolean) {
    return this.updatePriceInputs.find(
      (n) => n.productId === productId && n.machining === machining
    );
  }

  submit() {
    const client = new ApiClientFactory().priceClient();

    client.edit(this.updatePriceInputs).then(() => {
      // show toast
      this.$bvToast.toast('Chỉnh sửa thành công', {
        title: 'Set giá bán - chi phí vốn',
        toaster: 'b-toaster-bottom-right',
        variant: 'success'
      });
    });
  }
}
