import { Vue, Component } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BModal,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BTabs,
  BTab
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import Pagination from '@/@core/components/paginations/Pagination.vue';
import PriceAreaFormModal from '@/components/prices/PriceAreaFormModal.vue';
import OrdinaryPriceTab from '@/components/prices/OrdinaryPriceTab.vue';
import VipPriceTab from '@/components/prices/VipPriceTab.vue';
import PaperPriceTab from '@/components/prices/paper/PaperPriceTab.vue';
import StandeePriceTab from '@/components/prices/standee/StandeePriceTab.vue';
import CataloguePriceTab from '@/components/prices/catalogue/CataloguePriceTab.vue';
import { CustomerLevel } from '@/api/api';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    BCardText,
    Pagination,
    BImg,
    BBadge,
    PriceAreaFormModal,
    BTabs,
    BTab,
    OrdinaryPriceTab,
    VipPriceTab,
    PaperPriceTab,
    StandeePriceTab,
    CataloguePriceTab
  }
})
export default class PriceList extends Vue {
  CustomerLevel = CustomerLevel;
}
