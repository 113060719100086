import {
  AddPriceInput,
  MasterDataViewModel,
  PriceListViewModel,
  PriceValueInput
} from '@/api/api';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BModal,
  BForm,
  BCardText,
  BImg,
  BBadge
} from 'bootstrap-vue';
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full';
import { Vue, Component, Prop } from 'vue-property-decorator';
import ApiClientFactory from '@/api/apiClientFactory';
import { mapActions, mapState } from 'vuex';
import {
  SettingAction,
  SettingState,
  SETTING_STATE_NAMESPACE
} from '@/store/setting/setting.module-types';
import CustomLabel from '@/@core/components/labels/CustomLabel.vue';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import vSelect from 'vue-select';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    BCardText,
    BImg,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    CustomLabel,
    vSelect
  },
  computed: mapState(SETTING_STATE_NAMESPACE, [SettingState.masterData]),
  methods: mapActions(SETTING_STATE_NAMESPACE, [SettingAction.fetchMasterData])
})
export default class StandeeAddPriceModel extends Vue {
  @Prop({ type: Array, required: true })
  thresholds!: Array<{
    from: number;
    to: number;
    isMaximum: boolean;
  }>;

  masterData!: MasterDataViewModel;

  quantityItems: Array<{
    from: number;
    to: number;
    price: number;
  }> = [];

  products: Array<{
    id: number;
    name: string;
  }> = [];

  selectedProduct: any = {};

  get productOptions(): DropdownOption[] {
    const result: DropdownOption[] = [];
    this.products.map((s) => result.push({ text: s.name ?? '', value: s.id }));

    return result;
  }

  $refs!: {
    formRules: InstanceType<typeof ValidationProvider>;
    modal: InstanceType<typeof BModal>;
  };

  fetchStandeeUnPricing() {
    const client = new ApiClientFactory().priceClient();

    client
      .getUnpricingProductsByCategories(this.masterData.standeeCategoryIds)
      .then((result) => {
        this.products = result.map((s) => {
          return {
            id: s.id,
            name: s.name ?? ''
          };
        });
      });
  }

  sortStandeePrices(standeePrices: Array<PriceListViewModel>) {
    return standeePrices.slice().sort((a, b) => a.minQuatity - b.minQuatity);
  }

  openModal(): void {
    //this.input = this.getDefaultInputValue();
    this.quantityItems = this.thresholds.map((s) => {
      return {
        from: s.from,
        to: s.to,
        price: 0
      };
    });
    this.fetchStandeeUnPricing();
    this.$refs.modal.show();
  }

  closeModal() {
    this.$emit('onClose');
    this.$refs.modal.hide();
  }

  onProductChange() {
    console.log(this.selectedProduct);
  }

  submit(): void {
    const client = new ApiClientFactory().priceClient();
    const priceItems = this.quantityItems.map((s) => {
      return {
        minQuantity: s.from,
        maxQuantity: s.to,
        price: s.price,
        area: 0
      } as PriceValueInput;
    });
    client
      .addPrice({
        priceValueInputs: priceItems,
        isPricingByQuantity: true,
        productId: parseInt(this.selectedProduct.value),
        name: this.selectedProduct.text
      } as AddPriceInput)
      .then(
        (result) => {
          if (result) {
            // show toast
            this.$bvToast.toast('Chỉnh sửa thành công', {
              title: 'Thay Đổi Khoảng Giá',
              toaster: 'b-toaster-bottom-right',
              variant: 'success'
            });
            this.closeModal();
            return;
          }
          this.$bvToast.toast('Chỉnh sửa thất bại', {
            title: 'Thay Đổi Khoảng Giá',
            toaster: 'b-toaster-bottom-right',
            variant: 'danger'
          });
        },
        (err) => {
          this.$bvToast.toast('Chỉnh sửa thất bại', {
            title: 'Thay Đổi Khoảng Giá',
            toaster: 'b-toaster-bottom-right',
            variant: 'danger'
          });
          console.log(err);
        }
      );
  }
}
