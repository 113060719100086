import { UpdateQuantityThresholdItem } from './../../../api/api';
import {
  MasterDataViewModel,
  PriceListViewModel,
  UpdateQuantityThresholdInput
} from '@/api/api';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BModal,
  BForm,
  BCardText,
  BImg,
  BBadge
} from 'bootstrap-vue';
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full';
import { dispatchCataloguePriceAction } from '@/store/catalougePrice/catalouge-price.dispatch';
import { Vue, Component, Prop } from 'vue-property-decorator';
import ApiClientFactory from '@/api/apiClientFactory';
import { mapActions, mapState } from 'vuex';
import {
  SettingAction,
  SettingState,
  SETTING_STATE_NAMESPACE
} from '@/store/setting/setting.module-types';
import CustomLabel from '@/@core/components/labels/CustomLabel.vue';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    BCardText,
    BImg,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    CustomLabel
  },
  computed: mapState(SETTING_STATE_NAMESPACE, [SettingState.masterData]),
  methods: mapActions(SETTING_STATE_NAMESPACE, [SettingAction.fetchMasterData])
})
export default class StandeePriceThresholds extends Vue {
  @Prop({ type: Array, required: true })
  thresholds!: Array<{
    from: number;
    to: number;
    isMaximum: boolean;
  }>;

  masterData!: MasterDataViewModel;

  quantityItems: Array<{
    from: number;
    to: number;
    isMaximum: boolean;

    originalValue: {
      from: number;
      to: number;
    };
  }> = [];

  interactingThresHold: {
    from: number;
    to: number;
    index: number;
    originalValue: {
      from: number;
      to: number;
    };
  } = {
    from: 0,
    to: 0,
    index: -1,
    originalValue: {
      from: 0,
      to: 0
    }
  };

  isAddingState = true;
  validThresHoldState = true;

  $refs!: {
    formRules: InstanceType<typeof ValidationProvider>;
    modal: InstanceType<typeof BModal>;
  };

  fetchStandeePrice(standeeId) {
    const client = new ApiClientFactory().priceClient();

    client.getPrices(standeeId, null).then((result) => {
      const groupByCategory = result.reduce((group, price) => {
        const { productName, minQuatity, maxQuatity } = price;

        group[productName!] = group[productName!] ?? [];
        group[productName!].push(price);
        return group;
      }, {});
    });
  }

  sortStandeePrices(standeePrices: Array<PriceListViewModel>) {
    return standeePrices.slice().sort((a, b) => a.minQuatity - b.minQuatity);
  }

  openModal(): void {
    //this.input = this.getDefaultInputValue();
    this.quantityItems = this.thresholds.map((s) => {
      return {
        from: s.from,
        to: s.to,
        isMaximum: s.isMaximum,
        originalValue: s
      };
    });
    this.$refs.modal.show();
  }

  closeModal() {
    this.interactingThresHold = {
      from: 0,
      to: 0,
      index: -1,
      originalValue: {
        from: 0,
        to: 0
      }
    };

    this.$emit('onClose');
    this.$refs.modal.hide();
  }

  setInteractionThreshold(item, index) {
    this.validThresHoldState = true;
    this.isAddingState = true;
    if (item) {
      if (item.originalValue && item.originalValue.to > 0) {
        this.isAddingState = false;
      }

      this.interactingThresHold.from = item.from;
      this.interactingThresHold.to = item.to;
      this.interactingThresHold.index = index;

      this.interactingThresHold.originalValue = {
        from: item.from,
        to: item.to
      };
    }
  }

  Reset() {
    this.interactingThresHold = {
      from: 0,
      to: 0,
      index: -1,
      originalValue: {
        from: 0,
        to: 0
      }
    };
    this.isAddingState = true;
    this.validThresHoldState = true;
  }

  Remove(item) {
    const removeIndex = this.quantityItems.findIndex(
      (s) => s.from === item.from && s.to === item.to
    );

    if (removeIndex !== -1) {
      if (item.originalValue && item.originalValue.to > 0) {
        this.quantityItems[removeIndex].from = 0;
        this.quantityItems[removeIndex].to = 0;
      } else {
        this.quantityItems.splice(removeIndex, 1);
      }
    }
  }

  ApplyInteractingThreshold(isAdding) {
    if (!this.isValidThreshold(this.interactingThresHold)) {
      return;
    }
    if (isAdding === true) {
      this.quantityItems.push({
        from: this.interactingThresHold.from,
        to: this.interactingThresHold.to,
        isMaximum: false,
        originalValue: {
          from: 0,
          to: 0
        }
      });
    } else {
      this.quantityItems[
        this.interactingThresHold.index
      ].from = this.interactingThresHold.from;
      this.quantityItems[
        this.interactingThresHold.index
      ].to = this.interactingThresHold.to;
    }

    this.quantityItems = this.quantityItems
      .slice()
      .sort((a, b) => a.from - b.from);
    this.Reset();
  }

  isValidThreshold(threshold) {
    if (
      !threshold ||
      threshold.from === 0 ||
      threshold.to === 0 ||
      threshold.from >= threshold.to
    ) {
      this.validThresHoldState = false;
      return this.validThresHoldState;
    }

    let itemsToCompare = [...this.quantityItems];
    if (!this.isAddingState) {
      itemsToCompare = this.quantityItems.filter(
        (s) =>
          s.from !== this.interactingThresHold.originalValue.from &&
          s.to !== this.interactingThresHold.originalValue.to
      );
    }

    const invalidFrom = itemsToCompare.findIndex(
      (s) => s.from >= threshold.from || s.to >= threshold.from
    );
    const invalidTo = itemsToCompare.findIndex((s) => s.to >= threshold.to);
    this.validThresHoldState = invalidFrom === -1 && invalidTo === -1;
    return this.validThresHoldState;
  }

  submit(): void {
    const client = new ApiClientFactory().priceClient();
    const updateModelItems = this.quantityItems.map((s) => {
      return {
        from: s.from,
        to: s.to,
        originalValue: {
          from: s.originalValue.from,
          to: s.originalValue.to
        } as UpdateQuantityThresholdItem
      };
    });
    client
      .updateQuantityThresholdByCategories({
        categotyIds: this.masterData.standeeCategoryIds,
        updateQuantityThresholdInputs: updateModelItems
      } as UpdateQuantityThresholdInput)
      .then(
        (result) => {
          if (result) {
            // show toast
            this.$bvToast.toast('Chỉnh sửa thành công', {
              title: 'Thay Đổi Khoảng Giá',
              toaster: 'b-toaster-bottom-right',
              variant: 'success'
            });
            this.closeModal();
            return;
          }
          this.$bvToast.toast('Chỉnh sửa thất bại', {
            title: 'Thay Đổi Khoảng Giá',
            toaster: 'b-toaster-bottom-right',
            variant: 'danger'
          });
        },
        (err) => {
          this.$bvToast.toast('Chỉnh sửa thất bại', {
            title: 'Thay Đổi Khoảng Giá',
            toaster: 'b-toaster-bottom-right',
            variant: 'danger'
          });
          console.log(err);
        }
      );
  }
}
