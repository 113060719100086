import {
  CatalogueMaterialsViewModel,
  CataloguePriceListViewModel,
  CatalogueProductItemsPrice,
  CatalogUpdatePriceThresholdInput,
  MasterDataViewModel,
  SelectModelOfGuid,
  UpdateCatalogueProductItemPrice
} from '@/api/api';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BModal,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BFormSelectOption,
  BOverlay
} from 'bootstrap-vue';
import {
  CataloguePriceAction,
  CataloguePriceGetter,
  CataloguePriceNamespace
} from '@/store/catalougePrice/catalogue-price.module-types';
import { dispatchCataloguePriceAction } from '@/store/catalougePrice/catalouge-price.dispatch';
import { Vue, Component, Watch } from 'vue-property-decorator';
import ApiClientFactory from '@/api/apiClientFactory';
import { mapActions, mapState } from 'vuex';
import {
  SETTING_STATE_NAMESPACE,
  SettingAction,
  SettingState
} from '@/store/setting/setting.module-types';
import { groupBy, stringIsNumber } from '@/utility/utils';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    BCardText,
    BImg,
    BBadge,
    BFormSelectOption,
    BOverlay
  },
  computed: {
    ...mapState(SETTING_STATE_NAMESPACE, [SettingState.masterData])
  },
  methods: {
    ...mapActions(SETTING_STATE_NAMESPACE, [SettingAction.fetchMasterData])
  }
})
export default class CataloguePriceTab extends Vue {
  masterData!: MasterDataViewModel;
  fetchMasterData!: () => Promise<void>;

  isLoading = false;
  catalogueProductItems: SelectModelOfGuid[] = [];
  selectedCatalogueItems = '';
  cataloguePrices = {};
  cataloguePriceUpdates: UpdateCatalogueProductItemPrice[] = [];

  get pageOptions() {
    return this.masterData?.cataloguePageOptions ?? [];
  }

  get bookOptions() {
    return this.masterData?.catalogueBookOptions ?? [];
  }

  mounted() {
    this.isLoading = true;
    this.loadCatalogueProductItems().then((s) => {
      this.getCataloguePriceByProductItem();
    });
    if (!this.masterData) {
      this.fetchMasterData();
    }
  }

  sortByTotalPage(bookItem) {
    if (this.cataloguePrices && this.cataloguePrices[bookItem]) {
      const cataloguePriceByBook = [...this.cataloguePrices[bookItem]];
      return cataloguePriceByBook.sort((a, b) => a.totalPage - b.totalPage);
    }
    return [];
  }

  async loadCatalogueProductItems() {
    const client = new ApiClientFactory().productClient();
    const catalogueItems = await client.getCatalogueProductItems();

    this.catalogueProductItems = catalogueItems;
    if (catalogueItems.length > 0) {
      this.selectedCatalogueItems = catalogueItems[0].id;
    }
  }

  async getCataloguePriceByProductItem() {
    this.isLoading = true;

    const client = new ApiClientFactory().priceClient();
    const catalogueItemPrices = await client.getCatalogueProductItemsPrices(
      this.selectedCatalogueItems
    );

    this.cataloguePrices = groupBy(catalogueItemPrices, 'totalBook');

    this.isLoading = false;
  }

  onCatalogueProductItemChange() {
    this.getCataloguePriceByProductItem();
  }

  onPriceChange(event, item) {
    event.target.value =
      event.target.value && stringIsNumber(event.target.value)
        ? parseInt(event.target.value)
        : 0;
    const updatePrice = {
      id: item.id,
      price: stringIsNumber(event.target.value)
        ? parseInt(event.target.value)
        : 0
    } as UpdateCatalogueProductItemPrice;

    this.cataloguePriceUpdates.push(updatePrice);
  }

  async submitUpdatePrice() {
    this.isLoading = true;

    try {
      console.log(this.cataloguePriceUpdates);
      const client = new ApiClientFactory().priceClient();
      const result = await client.updateCatalogProductItemPrice(
        this.cataloguePriceUpdates
      );

      if (result) {
        this.$bvToast.toast('Cập nhật giá Catalogue thành công', {
          title: 'Set giá',
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        });

        this.isLoading = false;
        return;
      }
      this.$bvToast.toast('Cập nhật giá Catalogue thất bại', {
        title: this.$t('Order').toString(),
        toaster: 'b-toaster-bottom-right',
        variant: 'danger'
      });
    } catch (err) {
      this.$bvToast.toast('Cập nhật giá Catalogue thất bại', {
        title: this.$t('Order').toString(),
        toaster: 'b-toaster-bottom-right',
        variant: 'danger'
      });
    }

    this.isLoading = false;
  }
}
