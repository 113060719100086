import { getDispatch } from '../get-dispatch';
import { DispatchAction } from '../utility/dispatch-action';
import {
  CataloguePriceActions,
  CATALOGUE_PRICE_STATE_NAMESPACE
} from './catalogue-price.module-types';

export const dispatchCataloguePriceAction: DispatchAction<CataloguePriceActions> = getDispatch(
  CATALOGUE_PRICE_STATE_NAMESPACE
);
