import {
  MasterDataViewModel,
  PriceListViewModel,
  PriceValueInput
} from '@/api/api';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BModal,
  BForm,
  BCardText,
  BImg,
  BBadge
} from 'bootstrap-vue';
import { Vue, Component, Watch } from 'vue-property-decorator';
import ApiClientFactory from '@/api/apiClientFactory';
import { mapActions, mapState } from 'vuex';
import {
  SettingAction,
  SettingState,
  SETTING_STATE_NAMESPACE
} from '@/store/setting/setting.module-types';
import StandeePriceThresholds from '@/components/prices/standee/StandeePriceThresholdModal.vue';
import StandeeAddPrice from '@/components/prices/standee/StandeeAddPriceModel.vue';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    BCardText,
    BImg,
    BBadge,
    StandeePriceThresholds,
    StandeeAddPrice
  },
  computed: mapState(SETTING_STATE_NAMESPACE, [SettingState.masterData]),
  methods: mapActions(SETTING_STATE_NAMESPACE, [SettingAction.fetchMasterData])
})
export default class StandeePriceTab extends Vue {
  masterData!: MasterDataViewModel;
  fetchMasterData!: () => Promise<void>;

  standeePrices: any = {};
  quantities: Array<{
    from: number;
    to: number;
    isMaximum: boolean;
  }> = [];

  standeePricesUpdate: Array<{
    id: string;
    price: number;
  }> = [];

  mounted() {
    this.fetchMasterData().then((s) => {
      this.fetchStandeePrice();
    });
    this.standeePricesUpdate = [];
  }

  fetchStandeePrice() {
    const client = new ApiClientFactory().priceClient();
    const quantityItems: Array<{
      from: number;
      to: number;
    }> = [];

    client
      .getPrices(this.masterData.standeeCategoryIds, null)
      .then((result) => {
        const groupByCategory = result.reduce((group, price) => {
          const { productName, minQuatity, maxQuatity } = price;
          if (
            quantityItems.findIndex(
              (s) => s.from === minQuatity && s.to === maxQuatity
            ) === -1
          ) {
            quantityItems.push({
              from: minQuatity,
              to: maxQuatity
            });
          }

          group[productName!] = group[productName!] ?? [];
          group[productName!].push(price);
          return group;
        }, {});

        this.standeePrices = groupByCategory;

        const maxQuatity = Math.max(...quantityItems.map((o: any) => o.to));
        this.quantities = quantityItems
          .map((q: any) => {
            return {
              from: q.from,
              to: q.to,
              isMaximum: q.to === maxQuatity
            };
          })
          .sort((a, b) => a.from - b.from);
        // const groupKey = Object.keys(groupByCategory);
        // this.standeePrices = groupKey.map((k) => {
        //   return { ...groupByCategory[k].slice(0, 1).shift() };
        // });

        console.log(this.standeePrices);
      });
  }

  sortStandeePrices(standeePrices: Array<PriceListViewModel>) {
    return standeePrices.slice().sort((a, b) => a.minQuatity - b.minQuatity);
  }

  changeStandeePrice(standeePriceItem) {
    const existIndex = this.standeePricesUpdate.findIndex(
      (s) => s.id === standeePriceItem.id
    );
    if (existIndex !== -1) {
      this.standeePricesUpdate[existIndex] = standeePriceItem.price;
    } else {
      this.standeePricesUpdate.push({
        id: standeePriceItem.id,
        price: standeePriceItem.price
      });
    }
  }

  submit(): void {
    const client = new ApiClientFactory().priceClient();

    const updatePricesmodel = this.standeePricesUpdate.map((s) => {
      return {
        id: s.id,
        area: 0,
        price: s.price
      } as PriceValueInput;
    });
    client.updatePrices(updatePricesmodel).then(
      (result) => {
        if (result) {
          // show toast
          this.$bvToast.toast('Chỉnh sửa thành công', {
            title: 'Set giá catalouge',
            toaster: 'b-toaster-bottom-right',
            variant: 'success'
          });
          this.standeePricesUpdate = [];
          return;
        }

        this.$bvToast.toast('Chỉnh sửa thất bại', {
          title: 'Set giá catalouge',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
      },
      (err) => {
        this.$bvToast.toast('Chỉnh sửa thất bại', {
          title: 'Set giá catalouge',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
        console.log(err);
      }
    );
  }
}
