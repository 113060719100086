import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BImg,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody
} from 'bootstrap-vue';
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full';
import CustomLabel from '@/@core/components/labels/CustomLabel.vue';
import {
  PaperPricingType,
  PaperPricingUnit,
  CatalogueMaterialsViewModel,
  CataloguePriceListViewModel,
  CataloguePriceThresholdViewModel,
  CatalogUpdatePriceInput,
  CatalogUpdatePriceThresholdInput,
  ICataloguePriceListViewModel
} from '@/api/api';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import vSelect from 'vue-select';
import { MAX_INT_ALLOW } from '@/utility/constants';
import ApiClientFactory from '@/api/apiClientFactory';
import { dispatchCataloguePriceAction } from '@/store/catalougePrice/catalouge-price.dispatch';
import {
  CataloguePriceAction,
  CataloguePriceGetter,
  CataloguePriceNamespace
} from '@/store/catalougePrice/catalogue-price.module-types';

@Component({
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BModal,
    BForm,
    BImg,
    ValidationProvider,
    ValidationObserver,
    CustomLabel,
    vSelect,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardHeader,
    BCardBody
  }
})
export default class PaperPriceFormModal extends Vue {
  @Prop({ type: Object, required: false })
  catalogPriceProp!: CataloguePriceListViewModel;

  @CataloguePriceNamespace.Getter(CataloguePriceGetter.catalougePriceList)
  cataloguePriceList!: CataloguePriceListViewModel[];

  $refs!: {
    formRules: InstanceType<typeof ValidationProvider>;
    modal: InstanceType<typeof BModal>;
  };
  area?: number = undefined;
  index?: number = 0;

  catalogueMaterials: CatalogueMaterialsViewModel[] = [];

  interactingThreshold: {
    from: number;
    to: number;
  } = {
    from: 0,
    to: 0
  };
  validThresHoldState = true;
  cataloguePrice = this.getDefaultValue();

  productOptions: DropdownOption[] = [];
  selectedProduct: any = '';

  stringIsNumber = (value) => isNaN(Number(value)) === false;

  get isEditting(): boolean {
    return this.cataloguePrice?.id !== 0;
  }

  get pricingTypeOptions(): DropdownOption[] {
    const enumKeys = Object.keys(PaperPricingType);
    return enumKeys
      .filter(
        (s) =>
          this.stringIsNumber(s) && s !== PaperPricingType.Printing.toFixed()
      )
      .map((s) => {
        const enumValue = parseInt(s);

        return {
          value: parseInt(s),
          text: this.$t(PaperPricingType[enumValue])
        } as DropdownOption;
      });
  }

  get pricingUnitOptions(): DropdownOption[] {
    const enumKeys = Object.keys(PaperPricingUnit);
    return enumKeys
      .filter((s) => this.stringIsNumber(s))
      .map((s) => {
        const enumValue = parseInt(s);

        return {
          value: parseInt(s),
          text: this.$t(PaperPricingUnit[enumValue])
        } as DropdownOption;
      });
  }

  async openModal(event) {
    if (this.catalogueMaterials.length === 0) {
      const client = new ApiClientFactory().productClient();
      const materials = await client.getCatalogueMaterials();
      this.catalogueMaterials = materials ?? [];
    }

    this.cataloguePrice = this.getDefaultValue();
    if (this.catalogPriceProp && this.catalogPriceProp?.id !== 0) {
      this.cataloguePrice = this.catalogPriceProp;
    }
    this.onTypeChange();
    this.applyProductValue();
    this.$refs.modal.show();
  }

  openEditModal(area: number, index: number): void {
    this.area = area;
    this.index = index;
    this.$refs.modal.show();
  }

  applyProductValue() {
    switch (this.cataloguePrice.catalogPricingType) {
      case PaperPricingType.Paper:
        this.selectedProduct = this.cataloguePrice.productId;
        break;
      case PaperPricingType.Processing:
        this.selectedProduct = this.cataloguePrice.processingTypeId;
        break;
      case PaperPricingType.CoverPolyme:
        this.selectedProduct = this.cataloguePrice.polymeCoverTypeId;
        break;
      default:
        break;
    }
  }

  getThresholdLabel(threshold: CataloguePriceThresholdViewModel): string {
    if (threshold.minValue > 0 && threshold.maxValue === 0) {
      return `${threshold.minValue}++`;
    }
    return `${threshold.minValue} - ${threshold.maxValue}`;
  }

  onTypeChange() {
    const productOptions = this.getProductOptions(
      this.cataloguePrice.catalogPricingType
    );

    this.productOptions = productOptions;
    this.selectedProduct = 0;
  }

  getProductOptions(type: PaperPricingType) {
    const materialsFiltered = this.catalogueMaterials.filter(
      (s) => s.type === type
    );
    if (type === PaperPricingType.Paper) {
      const pricedProducts = this.cataloguePriceList
        .filter(
          (s) =>
            s.productId &&
            (!this.isEditting ||
              (this.isEditting &&
                s.productId !== this.cataloguePrice.productId))
        )
        .map((s) => s.productId);
      return materialsFiltered
        .filter((s) => pricedProducts.findIndex((p) => p == s.productId) === -1)
        .map((s) => {
          return {
            text: s.productName,
            value: s.productId
          } as DropdownOption;
        });
    }

    const pricedMaterials = this.cataloguePriceList.filter(
      (s) =>
        (s.processingTypeId || s.polymeCoverTypeId) &&
        (!this.isEditting ||
          (this.isEditting &&
          this.cataloguePrice.catalogPricingType === PaperPricingType.Processing
            ? s.processingTypeId !== this.cataloguePrice.processingTypeId
            : s.polymeCoverTypeId !== this.cataloguePrice.polymeCoverTypeId))
    );

    return materialsFiltered
      .filter(
        (s) =>
          pricedMaterials.findIndex(
            (p) =>
              p.polymeCoverTypeId === s.materialId ||
              p.processingTypeId === s.materialId
          ) === -1
      )
      .map((s) => {
        return {
          text: s.materialName,
          value: s.materialId
        } as DropdownOption;
      });
  }

  isValidThreshold() {
    if (
      !this.interactingThreshold ||
      this.interactingThreshold.from === 0 ||
      this.interactingThreshold.to === 0 ||
      this.interactingThreshold.to === MAX_INT_ALLOW ||
      this.interactingThreshold.from >= this.interactingThreshold.to
    ) {
      this.validThresHoldState = false;
      return this.validThresHoldState;
    }

    const itemsToCompare = [...(this.cataloguePrice.thresholdViewModels ?? [])];
    const invalidFrom = itemsToCompare.findIndex(
      (s) =>
        s.minValue >= this.interactingThreshold.from ||
        s.maxValue >= this.interactingThreshold.from
    );
    const invalidTo = itemsToCompare.findIndex(
      (s) => s.maxValue >= this.interactingThreshold.to
    );
    this.validThresHoldState = invalidFrom === -1 && invalidTo === -1;
    return this.validThresHoldState;
  }

  removeThreshold(threshold) {
    const removeIndex =
      this.cataloguePrice.thresholdViewModels?.findIndex(
        (s) =>
          s.maxValue === threshold.maxValue && s.minValue === threshold.minValue
      ) ?? -1;

    this.cataloguePrice.thresholdViewModels?.splice(removeIndex, 1);
  }

  ApplyInteractingThreshold() {
    if (this.isValidThreshold()) {
      this.cataloguePrice.thresholdViewModels?.push({
        id: '',
        minValue: parseInt(this.interactingThreshold.from.toString()),
        maxValue: parseInt(this.interactingThreshold.to.toString()),
        price: 0
      } as CataloguePriceThresholdViewModel);

      this.interactingThreshold = {
        from: 0,
        to: 0
      };
      this.validThresHoldState = true;
    }
  }

  async submit() {
    this.$refs.formRules.validate().then(async (success) => {
      if (success) {
        const client = new ApiClientFactory().priceClient();
        const model = {
          id: this.cataloguePrice.id,
          name: this.cataloguePrice.name,
          catalogPricingType: this.cataloguePrice.catalogPricingType,
          catalogPricingUnit: this.cataloguePrice.catalogPricingUnit,
          polymeCoverTypeId:
            this.cataloguePrice.catalogPricingType ==
            PaperPricingType.CoverPolyme
              ? this.selectedProduct
              : '',
          processingTypeId:
            this.cataloguePrice.catalogPricingType ==
            PaperPricingType.Processing
              ? this.selectedProduct
              : '',
          productId:
            this.cataloguePrice.catalogPricingType == PaperPricingType.Paper
              ? this.selectedProduct
              : 0,
          thresholdInputs: this.cataloguePrice.thresholdViewModels?.map((s) => {
            return {
              id: s.id,
              minValue: s.minValue,
              maxValue: s.maxValue,
              price: s.price
            } as CatalogUpdatePriceThresholdInput;
          })
        } as CatalogUpdatePriceInput;

        let result = false;
        if (this.isEditting) {
          result = await client.updateCatalogPrice(model);
        } else {
          result = await client.createCatalogPrice(model);
        }

        if (result === true) {
          this.$bvToast.toast(
            this.isEditting ? 'Chỉnh sửa thành công' : 'Thêm mới thành công',
            {
              title: 'Set giá catalouge',
              toaster: 'b-toaster-bottom-right',
              variant: 'success'
            }
          );

          dispatchCataloguePriceAction(
            CataloguePriceAction.loadCataloguePriceList
          );
          this.$refs.modal.hide();
          return;
        }

        this.$bvToast.toast(
          this.isEditting ? 'Chỉnh sửa thất bại' : 'Thêm mới thất bại',
          {
            title: 'Set giá catalouge',
            toaster: 'b-toaster-bottom-right',
            variant: 'danger'
          }
        );
      }
    });
  }

  getDefaultValue() {
    return {
      id: 0,
      catalogPricingType: PaperPricingType.Paper,
      catalogPricingUnit: PaperPricingUnit.Book,
      name: '',
      polymeCoverTypeId: '',
      processingTypeId: '',
      productId: undefined,
      thresholdViewModels: [
        {
          id: '',
          maxValue: 5,
          minValue: 1,
          price: 0
        },
        {
          id: '',
          maxValue: 10,
          minValue: 6,
          price: 0
        },
        {
          id: '',
          maxValue: 20,
          minValue: 11,
          price: 0
        },
        {
          id: '',
          maxValue: 50,
          minValue: 21,
          price: 0
        },
        {
          id: '',
          maxValue: 100,
          minValue: 51,
          price: 0
        },
        {
          id: '',
          maxValue: 300,
          minValue: 101,
          price: 0
        },
        {
          id: '',
          maxValue: 500,
          minValue: 301,
          price: 0
        },
        {
          id: '',
          maxValue: 1000,
          minValue: 501,
          price: 0
        },
        {
          id: '',
          maxValue: 2000,
          minValue: 1001,
          price: 0
        },
        {
          id: '',
          maxValue: 5000,
          minValue: 2001,
          price: 0
        },
        {
          id: '',
          maxValue: 9999,
          minValue: 5001,
          price: 0
        }
      ]
    } as CataloguePriceListViewModel;
  }
}
