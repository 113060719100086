var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card-header',{staticClass:"pr-0 pl-0"},[_c('h4',{staticClass:"mb-0"},[_vm._v("Set giá bán - chi phí vốn")]),_c('b-card-text',{staticClass:"font-medium-5 mb-0"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.submit}},[_vm._v(" Lưu ")])],1)],1),_c('b-card-body',{staticClass:"pr-0 pl-0"},[_c('div',{staticClass:"vgt-wrap"},[_c('table',{staticClass:"vgt-table bordered"},[_c('tr',[_c('th',[_c('span',[_vm._v("Stt")])]),_c('th',[_c('span',[_vm._v("Sản phẩm")])]),_c('th',[_c('span',[_vm._v("Gia công")])]),_c('th',[_c('span',[_vm._v(_vm._s(_vm.name))])])]),_vm._l((_vm.priceList),function(item,index){return [(item.haveMachining || item.noMachining)?[_c('tr',{key:'NoMachiningOrHaveMachining' + "" + (item.productId)},[_c('td',{attrs:{"rowspan":item.haveMachining && item.noMachining ? 2 : 1}},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('td',{attrs:{"rowspan":item.haveMachining && item.noMachining ? 2 : 1}},[_vm._v(" "+_vm._s(item.productName)+" ")]),(item.noMachining)?[_c('td',[_vm._v("Không cán màng")]),_c('td',[_c('b-form-input',{attrs:{"type":"number"},model:{value:(
                      _vm.getUpdatePriceInputByCondition(item.productId, false)
                        .priceValues[0].price
                    ),callback:function ($$v) {_vm.$set(_vm.getUpdatePriceInputByCondition(item.productId, false)
                        .priceValues[0], "price", $$v)},expression:"\n                      getUpdatePriceInputByCondition(item.productId, false)\n                        .priceValues[0].price\n                    "}})],1)]:[_c('td',[_vm._v("Cán màng hoặc cán mờ")]),_c('td',[_c('b-form-input',{attrs:{"type":"number"},model:{value:(
                      _vm.getUpdatePriceInputByCondition(item.productId, true)
                        .priceValues[0].price
                    ),callback:function ($$v) {_vm.$set(_vm.getUpdatePriceInputByCondition(item.productId, true)
                        .priceValues[0], "price", $$v)},expression:"\n                      getUpdatePriceInputByCondition(item.productId, true)\n                        .priceValues[0].price\n                    "}})],1)]],2)]:_vm._e(),(item.noMachining && item.haveMachining)?_c('tr',{key:'NoMachiningAndHaveMachining' + "" + (item.productId)},[_c('td',[_vm._v("Cán màng hoặc cán mờ")]),_c('td',[_c('b-form-input',{attrs:{"type":"number"},model:{value:(
                  _vm.getUpdatePriceInputByCondition(item.productId, true)
                    .priceValues[0].price
                ),callback:function ($$v) {_vm.$set(_vm.getUpdatePriceInputByCondition(item.productId, true)
                    .priceValues[0], "price", $$v)},expression:"\n                  getUpdatePriceInputByCondition(item.productId, true)\n                    .priceValues[0].price\n                "}})],1)]):_vm._e()]})],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }