import {
  PaperPricingUnit,
  CataloguePriceListViewModel,
  CatalogUpdatePriceThresholdInput,
  ICataloguePriceListViewModel
} from '@/api/api';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BModal,
  BForm,
  BCardText,
  BImg,
  BBadge
} from 'bootstrap-vue';
import {
  CataloguePriceAction,
  CataloguePriceGetter,
  CataloguePriceNamespace
} from '@/store/catalougePrice/catalogue-price.module-types';
import { dispatchCataloguePriceAction } from '@/store/catalougePrice/catalouge-price.dispatch';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import ApiClientFactory from '@/api/apiClientFactory';
import PaperPriceFormModal from '@/components/prices/paper/PaperPriceFormModal.vue';
import { stringIsNumber } from '@/utility/utils';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    BCardText,
    BImg,
    BBadge,
    PaperPriceFormModal
  }
})
export default class PaperPriceTable extends Vue {
  @Prop({ type: CataloguePriceListViewModel, required: true })
  value!: CataloguePriceListViewModel;

  updateCataloguePrice = {};

  getPricingType(value) {
    return PaperPricingUnit.Book === value ? 'Cuốn' : 'Trang';
  }

  onPriceChange(event, item) {
    event.target.value =
      event.target.value && stringIsNumber(event.target.value)
        ? parseInt(event.target.value)
        : 0;
    const updatePrice = {
      id: item.id,
      maxValue: item.maxValue,
      minValue: item.minValue,
      price: stringIsNumber(event.target.value)
        ? parseInt(event.target.value)
        : 0
    } as CatalogUpdatePriceThresholdInput;

    this.$emit('onUpdateThreshold', updatePrice);
  }
}
