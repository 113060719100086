import { Vue, Component, Watch } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BModal,
  BForm,
  BCardText,
  BImg,
  BBadge
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import Pagination from '@/@core/components/paginations/Pagination.vue';
import {
  PriceAction,
  PriceGetter,
  PriceNamespace
} from '@/store/price/price.module-types';
import {
  CustomerLevel,
  CutType,
  PriceListViewModel,
  PriceValueViewModel,
  PriceViewModel,
  UpdatePriceInput,
  PriceValueInput
} from '@/api/api';
import { dispatchPriceAction } from '@/store/price/price.dispatch';
import PriceAreaFormModal from '@/components/prices/PriceAreaFormModal.vue';
import PriceAreaFormModalClass from '@/components/prices/PriceAreaFormModal';
import ApiClientFactory from '@/api/apiClientFactory';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    BCardText,
    Pagination,
    BImg,
    BBadge,
    PriceAreaFormModal
  }
})
export default class OrdinaryPriceTab extends Vue {
  @PriceNamespace.Getter(PriceGetter.priceList)
  priceList!: PriceListViewModel[];
  areas: number[] = [];
  updatePriceInputs: UpdatePriceInput[] = [];
  CutType = CutType;
  $refs!: {
    priceAreaFormModal: InstanceType<typeof PriceAreaFormModalClass>;
  };

  mounted() {
    dispatchPriceAction(PriceAction.loadPriceList);
  }

  addArea() {
    this.$refs.priceAreaFormModal.openCreateModal();
  }

  @Watch('priceList')
  priceListUpdated() {
    // reset and set areas
    this.areasUpdated(undefined);
    if (
      this.priceList.length > 0 &&
      (this.priceList[0].priceViewModels?.filter(
        (_) => _.customerLevel === CustomerLevel.Ordinary
      ).length ?? 0) > 0
    ) {
      this.areasUpdated(
        (this.priceList[0].priceViewModels?.filter(
          (_) => _.customerLevel === CustomerLevel.Ordinary
        ) as PriceViewModel[])[0].value
      );
    }

    // reset and set updatePriceInputs
    this.updatePriceInputs = [];
    this.priceList.forEach((value) => {
      if (value.haveMachining || value.noMachining) {
        let priceViewModels: PriceViewModel[] = [];
        if (value.priceViewModels) {
          priceViewModels = value.priceViewModels.filter(
            (_) => _.customerLevel === CustomerLevel.Ordinary
          ) as PriceViewModel[];
        }

        if (value.noMachining) {
          // no machining and straight cut type
          this.updatePriceInputs.push(
            this.getUpdatePriceInputByFromPriceViewModels(
              priceViewModels,
              value.productId,
              false,
              CutType.Straight
            )
          );

          // no machining and special cut type
          this.updatePriceInputs.push(
            this.getUpdatePriceInputByFromPriceViewModels(
              priceViewModels,
              value.productId,
              false,
              CutType.Special
            )
          );
        }

        if (value.haveMachining) {
          // machining and straight cut type
          this.updatePriceInputs.push(
            this.getUpdatePriceInputByFromPriceViewModels(
              priceViewModels,
              value.productId,
              true,
              CutType.Straight
            )
          );

          // machining and special cut type
          this.updatePriceInputs.push(
            this.getUpdatePriceInputByFromPriceViewModels(
              priceViewModels,
              value.productId,
              true,
              CutType.Special
            )
          );
        }
      }
    });
  }

  getUpdatePriceInputByFromPriceViewModels(
    priceViewModels: PriceViewModel[],
    productId: number,
    machining: boolean,
    cutType: CutType
  ): UpdatePriceInput {
    return new UpdatePriceInput({
      productId,
      machining,
      cutType: cutType,
      customerLevel: CustomerLevel.Ordinary,
      priceValues: priceViewModels.some(
        (n) => n.machining === machining && n.cutType === cutType
      )
        ? priceViewModels.filter(
            (n) => n.machining === machining && n.cutType === cutType
          )[0].value
        : this.getDefaultPriceValues()
    });
  }

  getDefaultPriceValues(): PriceValueInput[] {
    return this.areas.map(
      (value) =>
        new PriceValueInput({
          id: '',
          area: value,
          price: 0,
          maxQuantity: 0,
          minQuantity: 0
        })
    );
  }

  areasUpdated(priceValueViewModels: PriceValueViewModel[] | undefined) {
    if (priceValueViewModels) {
      this.areas = priceValueViewModels.map((n) => n.area);
    } else {
      this.areas = [];
    }
  }

  getUpdatePriceInputByCondition(
    productId: number,
    machining: boolean,
    cutType: CutType
  ) {
    return this.updatePriceInputs.find(
      (n) =>
        n.productId === productId &&
        n.machining === machining &&
        n.cutType === cutType
    );
  }

  priceAreaFormModalOnSubmit(area: number, index?: number) {
    const isExisting = this.areas.some(
      (value, i) => value === area && i !== index
    );

    if (isExisting) {
      // show toast
      this.$bvToast.toast('Diện tích đã tồn tại.', {
        title: 'Diện tích',
        toaster: 'b-toaster-bottom-right',
        variant: 'danger'
      });
      return;
    }

    if (index !== undefined) {
      this.areas[index] = area;

      this.updatePriceInputs.forEach((updatePriceInput) => {
        (updatePriceInput.priceValues as PriceValueInput[])[index].area = area;
      });
    } else {
      this.areas.push(area);
      this.updatePriceInputs.forEach((updatePriceInput) => {
        updatePriceInput.priceValues?.push(
          new PriceValueInput({
            id: '',
            area,
            price: 0,
            maxQuantity: 0,
            minQuantity: 0
          })
        );
      });
    }

    this.orderArea();
    this.orderPriceValues();

    // hide modal
    this.$refs.priceAreaFormModal.$refs.modal.hide();
  }

  orderArea() {
    this.areas = this.areas.sort((a, b) => a - b);
  }

  orderPriceValues() {
    this.updatePriceInputs.forEach((updatePriceInput) => {
      updatePriceInput.priceValues = updatePriceInput.priceValues?.sort(
        (a, b) => a.area - b.area
      );
    });
  }

  editArea(index: number) {
    this.$refs.priceAreaFormModal.openEditModal(this.areas[index], index);
  }

  removeArea(index: number) {
    this.areas.splice(index, 1);

    this.updatePriceInputs.forEach((updatePriceInput) => {
      updatePriceInput.priceValues?.splice(index, 1);
    });
  }

  submit() {
    const client = new ApiClientFactory().priceClient();

    client.edit(this.updatePriceInputs).then(() => {
      // show toast
      this.$bvToast.toast('Chỉnh sửa thành công', {
        title: 'Set giá bán - chi phí vốn',
        toaster: 'b-toaster-bottom-right',
        variant: 'success'
      });
    });
  }
}
