import {
  CataloguePriceListViewModel,
  CatalogUpdatePriceThresholdInput
} from '@/api/api';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BModal,
  BForm,
  BCardText,
  BImg,
  BBadge
} from 'bootstrap-vue';
import {
  CataloguePriceAction,
  CataloguePriceGetter,
  CataloguePriceNamespace
} from '@/store/catalougePrice/catalogue-price.module-types';
import { dispatchCataloguePriceAction } from '@/store/catalougePrice/catalouge-price.dispatch';
import { Vue, Component, Watch } from 'vue-property-decorator';
import ApiClientFactory from '@/api/apiClientFactory';
import PaperPriceTable from './PaperPriceTable.vue';
import PaperPriceFormModal from '@/components/prices/paper/PaperPriceFormModal.vue';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    BCardText,
    BImg,
    BBadge,
    PaperPriceTable,
    PaperPriceFormModal
  }
})
export default class PaperPriceTab extends Vue {
  @CataloguePriceNamespace.Getter(CataloguePriceGetter.catalougePriceList)
  cataloguePriceList!: CataloguePriceListViewModel[];
  updateCataloguePriceThresholdInputs: CatalogUpdatePriceThresholdInput[] = [];

  mounted() {
    dispatchCataloguePriceAction(CataloguePriceAction.loadCataloguePriceList);
  }

  async submitThresholdPrice() {
    const client = new ApiClientFactory().priceClient();
    const result = await client.updateCatalogPriceThresholds(
      this.updateCataloguePriceThresholdInputs
    );

    if (result) {
      this.$bvToast.toast('Chỉnh sửa thành công', {
        title: 'Set giá catalouge',
        toaster: 'b-toaster-bottom-right',
        variant: 'success'
      });

      this.updateCataloguePriceThresholdInputs = [];
    } else {
      this.$bvToast.toast('Chỉnh sửa thất bại', {
        title: 'Set giá catalouge',
        toaster: 'b-toaster-bottom-right',
        variant: 'danger'
      });
    }
  }

  onUpdateThresholdPrice(value: CatalogUpdatePriceThresholdInput) {
    value.price = value.price ?? 0;
    this.updateCataloguePriceThresholdInputs.push(value);
  }

  // submit(): void {
  //   const client = new ApiClientFactory().cataloguePriceClient();

  //   client.editPrice(this.updateCataloguePriceInputs).then(() => {
  //     // show toast
  //     this.$bvToast.toast('Chỉnh sửa thành công', {
  //       title: 'Set giá catalouge',
  //       toaster: 'b-toaster-bottom-right',
  //       variant: 'success'
  //     });
  //   });
  // }
}
