import { Vue, Component } from 'vue-property-decorator';
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BImg
} from 'bootstrap-vue';
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full';
import CustomLabel from '@/@core/components/labels/CustomLabel.vue';

@Component({
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BModal,
    BForm,
    BImg,
    ValidationProvider,
    ValidationObserver,
    CustomLabel
  }
})
export default class CataloguePriceFormModal extends Vue {
  $refs!: {
    formRules: InstanceType<typeof ValidationProvider>;
    modal: InstanceType<typeof BModal>;
  };
  area?: number = undefined;
  index?: number = 0;

  openCreateModal(): void {
    this.area = undefined;
    this.index = undefined;
    this.$refs.modal.show();
  }

  openEditModal(area: number, index: number): void {
    this.area = area;
    this.index = index;
    this.$refs.modal.show();
  }

  submit() {
    this.$refs.formRules.validate().then((success) => {
      if (success) {
        this.$emit('submit', this.area, this.index);
      }
    });
  }
}
